import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Themeroutes from './routes/Router';
import Loader from './layouts/loader/Loader';
import './assets/scss/style.scss';
import { AuthProvider } from './hooks/useAuth';

const App = () => {
  const routing = useRoutes(Themeroutes);
  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
      <div className="rtl"  dir="rtl">
        {routing}
      </div>
      </AuthProvider>
      <ToastContainer rtl="true" position="bottom-center"/>
    </Suspense>
  );
};

export default App;
