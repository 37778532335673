import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const ProtectedLayout = Loadable(lazy(() => import('../layouts/ProtectedLayout')));
const GlobalLayout = Loadable(lazy(() => import('../layouts/GlobalLayout')));
/***** Pages ****/
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../pages/Error')));
const LoginForm = Loadable(lazy(() => import('../pages/auth/loginForm')));

/*****Routes******/

const QrCode = Loadable(lazy(() => import('../pages/common/qrcode')));
const BarCode = Loadable(lazy(() => import('../pages/common/barcode')));

const ThemeRoutes = [
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      { path: '/', name: 'خانه', element: <Navigate to="/qrcode" /> },
      { path: '/qrcode', name: 'چک بلیت', exact: true, element: <QrCode /> },
      { path: '/barcode', name: 'چک بلیت', exact: true, element: <BarCode /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <GlobalLayout />,
    children: [
      { path: '', element: <Navigate to="/auth/login" /> },
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'login', element: <LoginForm /> },
    ],
  },
];

export default ThemeRoutes;
