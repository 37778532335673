import { useState, createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [token, setToken] = useState(Cookies.get("token") || null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (data) => {
    setToken(data.accessToken);
    Cookies.set("token",data.accessToken)
    Cookies.set("refreshToken",data.refreshToken);
    navigate("/qrcode");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setToken(null);
    Cookies.remove("token")
    Cookies.remove("refreshToken")
    navigate("/", { replace: true });
  };


  const value = useMemo(
    () => ({
      token,
      login,
      logout,
    }),
    [token]
  );
  return <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export const useAuth = () => {
  return useContext(AuthContext);
};